.number-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: black;
  }
  
  .number-input-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  
  .number-input-button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
  }
  
  .number-input-button:hover {
    background-color: #e0e0e0;
  }
  
  input[type="number"] {
    flex: 1;
    text-align: center;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  