.main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  
  @media (max-width: 768px) {

    .main {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }

    .fonts {
      font-size: 12px; /* Corrected without quotes */
    }
  
    .image {
      max-width: 87%;
      margin-left: 6vw;
      height: auto;
    }
  }