.read-only-form {
    width: 80%;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.fieldset-section {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
}

.legend {
    font-weight: bold;
    font-size: 1.2em;
    color: #4a90e2;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

label {
    font-weight: 600;
    margin-bottom: 5px;
    color: #333;
}

input, textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    background-color: #fff;
}

textarea {
    resize: none;
    height: 60px;
}

.image-upload {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.uploaded-img {
    max-width: 300px;
    max-height: 300px;
    border-radius: 10px;
    border: 2px solid #ddd;
    object-fit: cover;
}

h2 {
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 1.5em;
}
