ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
}

li ol > li {
    margin: 0;
}

li ol > li:before {
    content: counters(item, ".") ". ";
}

.termsAndConditionMain{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.termsAndCondition{
    width: 80%;
    padding: 10%;
    margin-top: 40px;
    color: black;
    background-color: rgb(255, 254, 251);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
}
.headingContainerTC{
    display: flex;
    justify-content: center;
    width: 100%;

}
.headingTC{
    margin-bottom: 40px;
    width: 400px;
    color: #00cc66;
    font-size: 40px;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.listMainTC{
    font-weight: 600;
    font-size: 18px;
}
.listContentTC{
    font-weight: normal;
    font-size: 16px;
}


@media (max-width: 768px){
    .headingTC {
        margin-bottom: 30px;
        font-size: 30px;
        width: 300px;
    }
    .termsAndCondition{
        width: 85%;
        padding: 7%;
        margin-top: 35px;
    }
    .listMainTC{
    font-weight: 600;
    font-size: 17px;
    }
    .listContentTC{
        font-weight: normal;
        font-size: 14px;
    }
}

@media (max-width: 500px){
    .headingTC {
        margin-bottom: 20px;
        font-size: 26px;
        width: 250px;
    }
    .termsAndCondition{
        width: 90%;
        padding: 5%;
        margin-top: 20px;
    }
    .listMainTC{
    font-weight: 500;
    font-size: 16px;
    }
    .listContentTC{
        font-weight: normal;
        font-size: 12px;
    }
}