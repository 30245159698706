.logInMain {
    display: flex;
    width: 40%;
    height: 70vh;
    justify-content: center;
    align-items: center;
}



.loginContainer {
    z-index: 2;
    width: 90%;
    padding: 32px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
}

.form-section2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.logIn {
    width: 800px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 50px;
    margin: 80px;
}

.logInForm {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logInForm h2 {
    color: #000066;
    text-align: left;
}

.logInInput {
    display: flex;
    flex-direction:column;
    width: 100%;
}

.logInName, .logInPass {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 2px solid black;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 5px;
    flex-direction: row;
    padding-left: 5px;
}

.logInInputName, .logInInputPass {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    border: none;
    outline: none;
}

.showPassword {
    color: black;
    font-size: 14px;
    width: 15%;
    cursor: pointer;
}

.logInSubmit {
    display: flex;
    width: 100%;
    justify-content: center;
}

.logInButton {
    background-color: #00cc66;
    padding: 10px 100px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {

    .form-section2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
    }
    .logInMain {
        height: 70%;
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    .logIn {
        width: 450px;
        height: 400px;
    }

    .logInForm h2 {
        font-size: 30px;
    }
}

@media (max-width: 500px) {
    .logInMain {
        width: 100%;
    }
    .loginContainer {
        align-self: center;
        font-size: 12px;
    }
    .form-section2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .logIn {
        width: 85%;
        height: 350px;
        padding: 15px;
        margin: 0;
    }

    .logInForm {
        height: 100%;
        width: 100%;
    }

    .showPassword {
        font-size: 12px;
    }

    .logInButton {
        padding: 10px 40px;
    }
}



@media (max-width: 360px) {

    .loginContainer {
        align-self: center;
        font-size: 12px;
    }
    .logInMain {
        width: 100%;
    }

    .form-section2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .logIn {
        width: 85%;
        height: 350px;
        padding: 15px;
        margin: 0;
    }

    .logInForm {
        height: 100%;
        width: 100%;
    }

    .showPassword {
        font-size: 12px;
    }

    .logInButton {
        padding: 10px 40px;
    }
}
