/* Container styling */
.customerTable {
  width: 95%;
  margin: 6px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.pay-button, .retry-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover, .retry-button:hover {
  background-color: #45a049;
}

/* Customer Table Title */
.customerTableTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.customerTableTitle h2 {
  margin-left: 10px;
  color: #333;
  font-size: 1.5rem;
}

.backArrow {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.arrow {
  width: 25px;
  height: 25px;
}

/* LogOut Button */
.logOutButton button {
  width: 100px;
  background-color: #ff3333;
  padding: 10px;
  border-radius: 8px;
  border: none;
  color: wheat;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logOutButton button:hover {
  background-color: #ff6666;
}

/* Customer Count Box */
.customerCountBox {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.customerCountSubBox {
  display: flex;
  justify-content: center;
  width: 100%;
}

.customerCountCardsTotal {
  padding: 10px 20px;
  background-color: #e6f7ff;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  transition: background-color 0.3s ease;
}

.customerCountCardsTotal:hover {
  background-color: #b3e0ff;
}

.customerType {
  font-size: 1.2rem;
  color: #333;
}

.customerCount {
  font-size: 1.5rem;
  color: #1890ff;
}

/* Table Styling */
.customerDataTable {
  width: 90%;
  overflow-x: auto;
  position: relative;
  top: -30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

thead {
  background-color: #1890ff;
  color: white;
}

th, td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  font-size: 1rem;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody tr:hover {
  background-color: #e6f7ff;
}

.customerDataTableDoneButton {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.customerDataTableDeleteButton {
  background-color: hsl(0, 100%, 55%);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.customerDataTableDoneButtonView {
  background-color: #922e00;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.customerDataTableDoneButton:hover {
  background-color: #40a9ff;
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif;
}

/* Previous/Next Button */
.pagination-btn {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #d55d5d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-btn:hover:not(:disabled) {
  background-color: #45a049;
}

/* Pagination Numbers */
.pagination-numbers {
  display: flex;
  flex-wrap: wrap;
}

.pagination-number {
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #f1f1f1;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-number:hover {
  background-color: #d55d5d;
  color: white;
}

.pagination-number.active {
  background-color: #d55d5d;
  color: white;
  font-weight: bold;
  border: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .customerTableTitle h2 {
    font-size: 1.2rem;
  }

  .arrow {
    width: 20px;
    height: 20px;
  }

  .pagination-btn {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .pagination-number {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  th, td {
    padding: 10px;
    font-size: 0.9rem;
  }

  .customerCount {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .customerTable {
    width: 100%;
    padding: 10px;
  }

  .customerTableTitle {
    flex-direction: column;
    align-items: flex-start;
  }

  .logOutButton button {
    padding: 8px;
    font-size: 0.8rem;
  }

  .pagination {
    flex-direction: column;
  }

  .pagination-numbers {
    justify-content: center;
    margin-top: 10px;
  }
}
