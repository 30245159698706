.dashboard-container {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.hero-section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50vh;
  background-color: violet;
  margin-top: 20px;
}


    .cardsMain{
      display: flex;
      flex-direction: column;
      padding: 16px;
      justify-content: center;
      align-items: center;
  }

.download {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-left: 36px;
}

.Cardscomp {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.carousel-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 12px;
  margin-top: 20px;
}

.carousel-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 16px;
}

.carousel-item {
  flex: 1 1 300px;
}

.text-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  padding: 0 16px;
}

.text-content {
  text-align: center;
  width: 100%;
  max-width: 800px;
  padding: 24px;
}

.text-heading {
  color:#d55d5d;
}

.card{
  width: 18rem;
  }

.text-container{
    width: 70%; 
    padding: 24px;
}

.text-paragraph {
  color: black;
  padding: 24px;
}

.highlight {
  color: violet;
  text-decoration: underline;
}

.image-content {
  margin-top: 24px;
}

.scanner-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .hero-section {
    height: 30vh; /* Adjust for smaller screens */
  }

  .card {
    width: 12rem;
}

  .img {
    width: 23vw;
    border-radius: 20px;
    margin-bottom: 10px;
}

.Cardscomp {
  padding: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  justify-content: center;
}

  .text-container.aos-init.aos-animate {
    width: auto;
}

.subcomp {
  width: 100%;
}

.img {
  width: 25vw;
  border-radius: 20px;
  margin-bottom: 10px;
}

/* p {
  font-size: 12px;
  color: black;
  line-height: 22px; */
/* } */


  .cardsMain{
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

  .carousel-content {
    flex-direction: column;
  }

  .carousel-item {
    flex: 1 1 100%; /* Full width on smaller screens */
  }

  .text-container{
    /* width: 70%;  */
    padding: 24px;
}


  .text-content {
    padding: 16px;
  }

  .image-content {
    margin-top: 16px;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 600px) {
  .hero-section {
    height: 30vh; /* Adjust for smaller screens */
  }

  .Cardscomp {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
  }

  .subcomp {
    display: flex;
    margin-bottom: 2rem;
    width: 100%;
}

  .text-container.aos-init.aos-animate {
    width: auto;
}
.download {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}


  .cardsMain{
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

  .carousel-content {
    flex-direction: column;
  }

  .carousel-item {
    flex: 1 1 100%; /* Full width on smaller screens */
  }

  .text-container{
    /* width: 70%;  */
    padding: 24px;
}


  .text-content {
    padding: 16px;
  }

  .image-content {
    margin-top: 16px;
  }
}

@media (max-width: 360px) {

  .carousel.slide.mt-4.rounded {
    display: flex;
    flex-direction: column;
}
  .cardsMain{
    display: flex;
     flex-direction: column;
     justify-content: center;
   }

  .Cardscomp {
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    font-size: small;
}

p.card-text {
  font-size: 12px;
  line-height: 19px;
}

  .texts{
    color: rgb(0, 0, 102);
      width: 100%;
      /* padding: 24px; */
      text-align: start;
      font-size: 12px;
  }
.download {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-left: 0%;
}
 
.subcomp{
align-self: center;
}
}
