
.travo {
    color: #0f0e11;
    font-weight: bold;
    font-size: 24px;
    margin-left: 8px;
    text-decoration: none;
}

.navbar navbar-expand-lg navbar-light{
    width: 100%;
    z-index: 1000;
    background-color: rgb(255, 255, 255);
}


.navbar{
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.logo{
    width: 70px
}

.navbar-nav {
    /* width: 70%; */
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

.navbar .navbar-collapse{
    flex-grow: 0;
    
}

@media (max-width: 768px) { 
    ul.navbar-nav.mx-auto.mb-2.mb-lg-0 {
        flex-direction: column;
        align-items: center;
    }

    .navbar navbar-expand-lg navbar-light{
        width: 100%;
        z-index: 1000;
        background-color: rgb(255, 255, 255);
    }

    .travo{
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        margin-left: 8px;
        margin-top: 8px;
        text-decoration: none;
    }


.logo{
    width: 40px 
}
    
}


@media (max-width: 524px) { 


    .navbar navbar-expand-lg navbar-light{
        width: 85%;
        z-index: 1000;
        background-color: rgb(255, 255, 255);
    }

    .navbar-nav {
        flex-direction: column;
        align-items: center;

    }

    .travo{
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        margin-left: 8px;
        margin-top: 8px;
        text-decoration: none;
    }

    .logo{
        width: 40px  
    }

    .navbar-brand {
        white-space: nowrap;
    }

}