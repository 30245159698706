.form-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 16px;
    width: '50%';
  }
  
  .full-width {
    grid-column: span 2; 
  }

  .form-section {
    display: flex;
   
    flex-direction: column;
}
.form-section2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 16px;
}



@media (max-width: 560px) {
  .logInMain {
      width: 100%;
  }

  .form-section2 {
    grid-template-columns: repeat(1, 1fr);
    row-gap: inherit;
}

p {
  font-size: 10px;
  line-height: 22px;
}

}


@media (max-width: 360px) {
  .logInMain {
      width: 100%;
  }

  .form-section2 {
    grid-template-columns: repeat(1, 1fr);
    row-gap: inherit;
}

}