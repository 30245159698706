

.title-style {
    color: black;
}

p{
    color: black;
}
.customer-details-container {
    font-family: "Arial", sans-serif;
    margin: 20px auto;
    width: 90%;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .page-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .back-button,
  .edit-button,
  .save-button {
    padding: 10px 15px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .back-button {
    background-color: #555;
    color: white;
  }
  
  .edit-button {
    background-color: #007bff;
    color: white;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .details-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-picture {
    width: 130px;
    height: 130px;
    border: 2px solid #ddd;
  }
  
  .details-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .details-column {
    flex: 1;
  }
  
  .input-field,
  .textarea-field {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .loading-text {
    text-align: center;
    color: #007bff;
  }
  
  .error-text {
    text-align: center;
    color: red;
  }
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  select:focus {
    border-color: #007bff;
    outline: none;
  }
  input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  input[type="date"]:focus {
    border-color: #007bff;
    outline: none;
  }
  .custom-datepicker {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .custom-datepicker:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
  }
  
  .react-datepicker__day:hover {
    background-color: #e9ecef;
    color: black;
  }
  .select-80 {
    width: 80%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  .select-50 {
    width: 50%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  .select-31 {
    width: 31%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }