.imageCarouselMain {
  align-self:center;
  overflow:hidden;
  width: 1300px;
  height: 600px;
  margin:auto;
}

.carousel-control-prev{
width:20px;
height:20px;
margin:auto;
}

.carousel-control-prev-icon{
  background-color: #fbb13c;
}
.carousel-control-next-icon{
  background-color: #fbb13c;
}

.carousel-control-next{
  width:20px;
height:20px;
  margin:auto;
  
  }
@media(min-width: 1500px)and (min-height: 1000px){
  .imageCarouselMain {
      width: 50%;
      height: 300px;
  }
  .carousel-control-prev{
    display: none;
    }
    .carousel-control-next{
      display: none;
      }

}
@media(min-width: 700px)and (min-height: 1000px){
  .imageCarouselMain {
      width: 75%;
      height: 450px;
  }
  .carousel-control-prev{
    display: none;
    }
    .carousel-control-next{
      display: none;  
      }
}

@media(max-width: 700px){
  .imageCarouselMain {
      width: 80%;
      height: 300px;
  }
  .carousel-control-prev{
    margin:auto;
    display: none;
    }
    .carousel-control-next{
      margin:auto;
      display: none;
      }
}
@media(max-width: 500px){
  .imageCarouselMain {
    width: 100%;
    height: 200px;
}
  
  .carousel-control-prev{
    margin:auto;
    display: none;
    }
    .carousel-control-next{
      margin:auto;
      display: none;
      
      }
}