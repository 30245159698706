.checkout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: white;
}

.payment-section {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.amount-details {
  margin-bottom: 20px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
  color: #4CAF50;
}

.pay-button, .retry-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover, .retry-button:hover {
  background-color: #45a049;
}

.spinner {
  border: 4px solid rgb(248, 242, 242);
  border-left-color: #3faf50;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  align-self: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.loading-section, .success-section, .failed-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  font-size: 50px;
  animation: spin 2s linear infinite;
  color: #4CAF50;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-icon, .failed-icon {
  font-size: 60px;
  margin-bottom: 20px;
}

.success-icon {
  color: #4CAF50;
}

.failed-icon {
  color: #f44336;
}

h2 {
  color: #333;
}

p {
  color: #777;
}
