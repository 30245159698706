/* src/components/Footer.css */

footer {
  padding-top: 50px;
  padding-bottom: 10px;
}

footer .text-white a {
  color: #ffffff;
  text-decoration: none;
}

footer .text-white a:hover {
  text-decoration: underline;
}

.termsAndPrivacyBlock {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  font-size: 14px;
}
.Termsconditions{
  padding-left: 8px;
  padding-right: 8px;
  color: white;
}
.PrivacyPolicy{
  padding-left: 8px;
  padding-right: 8px;
  color: white;
}