/* Style for calendar digits */
.react-datetime-picker {
    --calendar-text-color: violet; /* CSS variable for text color */
  }


  .custom-datepicker {
    width: 100%;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid #b366ff;
    font-size: 14px;
    color: #333;
  }
  
  .custom-datepicker:focus {
    border-color: #4caf50; 
    outline: none;
    border-color: #b366ff;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.3); 
  }
  

  .react-datetime .rdtDay {
    color: violet; 
  }
  .react-datetime .rdtMonth {
    color: violet; 
  }
  .react-datetime .rdtYear {
    color: violet; 
  }

  td.rdtMonth, td.rdtYear {
    color: violet;
}

.rdtPicker .dow {
    color: VIOLET;
}

.rdtPicker td {
    color: rgb(11, 10, 11);
    cursor: pointer;
}

.rdtPicker td.rdtToday {
    background-color: blueviolet;
    color: white;
}

.rdtPicker thead tr:first-of-type th {
    cursor: pointer;
    color: violet;
}
.react-datetime input {
    border-width: 2px; /* Border width */
    border-color: #ccc; /* Border color */
    border-radius: 8px; /* Rounded corners */
    padding: 8px; /* Padding */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow */
  }

  .custom-date-picker {
    border: 1px solid #8e8e8e; /* Border color */
    border-radius: 8px; /* Border radius */
    padding: 67px; /* Padding */
  }
  
  .custom-time-picker {
    border: 1px solid #8e8e8e; 
    border-radius: 8px;
    width: 100%; 
    padding: 6px;
  }


   .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root {
    height: 38px;
    border-radius: 10px;
    border-color: black;
  } 

  .css-lrgvhg {
    height: 40px;
  }

   .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.Mui-readOnly.MuiInputBase-readOnly.css-lrgvhg {
    height: 38px;
} 


  .css-z3c6am-MuiFormControl-root-MuiTextField-root {
    border-width: 1px;
    border-radius: 8px;
    height: 40px;
    border-color: black;
  }

  .form-control{
    border-color:blueviolet;
    border-radius: 8;
    border-width: 1;
  }



@media (max-width: 360px) {
  .rdtPicker {
    display: none;
    position: absolute;
    min-width: 100%;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    border: 1px solid #f9f9f9;
}

}
  
  

